import { useEffect, useMemo, useState } from 'react';
import style from './Stats.module.scss';

// Compoents
import Flag from 'react-world-flags';
import countryTimezone from 'country-timezone';
import { VictorySharedEvents } from 'victory-shared-events';
import { VictoryLabel, VictoryLegend, VictoryPie } from 'victory';
import { Box, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';

// Utils
import { DATE_SETTINGS } from '../../../../utils/config';
import { getFormattedDate } from '../../../../utils/helpers';
import useWindowDimensions from '../../../../utils/getWindowResize';
import { getFontSize, getUnitOfMeasure, colorScaleCodes, Country, defaultLegend } from './helpers';

// Redux
import { useGetStatsQuery } from '../../../../stores/Dashboard';
import { useAppSelector } from '../../../../stores';
import { hasAccessTo } from '../../../../utils/user';
import { getPercentage } from '../../../Dashboard/helpers';

const Stats = () => {
    const { user } = useAppSelector((state) => state.login);
    const [selectedCountry, setSelectedCountry] = useState<Country>('RO');

    useEffect(() => {
        if (user && hasAccessTo(user, ['EVS.ChargingStations.READ'])) {
            setSelectedCountry('AT');
        }
    }, [user]);

    const { data: countryData } = useGetStatsQuery({});

    const legend = countryData ? countryData.legend : defaultLegend;

    const [data = [], selectedLegend, countries, colorScale, totalProduction, timestamp] = useMemo(() => {
        if (!countryData) {
            return [[], {}, [], [], 0, ''];
        }

        const values = countryData.data[selectedCountry].value;
        const totalProduction = Object.keys(values).reduce(
            (acc, crt) => acc + parseFloat((values as any)[crt as any]),
            0
        );

        return [
            Object.values(values).map((y, index) => ({ x: index, y })),
            Object.keys(values).map((key) => ({
                name: `${getPercentage(totalProduction, (values as any)[key as any])}%  ${
                    (legend as any)[key as any]
                } - ${(values as any)[key as any]} ${getUnitOfMeasure(
                    countryData.data[selectedCountry].unit_of_measure
                )}`
            })),
            Object.keys(countryData.data),
            Object.keys(values).map((e) => colorScaleCodes[e]),
            totalProduction,
            countryData.data[selectedCountry].timestamp
        ];
    }, [countryData, selectedCountry]);

    const getCountryName = (id: string) => {
        const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        return regionNames.of(id);
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedCountry(event.target.value as Country);
    };

    const getMouseOverHandler = () => {
        return {
            onMouseOver: () => {
                return [
                    {
                        target: 'data',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 0.2
                                }
                            };
                        }
                    },
                    {
                        target: 'labels',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 0.2
                                }
                            };
                        }
                    },
                    {
                        target: 'data',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 1
                                }
                            };
                        }
                    },
                    {
                        target: 'labels',
                        childName: ['pie', 'legend'],
                        mutation: (props: any) => {
                            return {
                                style: {
                                    ...props.style,
                                    opacity: 1
                                },
                                active: true
                            };
                        }
                    }
                ];
            },
            onMouseOut: () => {
                return [
                    {
                        target: 'data',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: () => {
                            return null;
                        }
                    },
                    {
                        target: 'labels',
                        eventKey: 'all',
                        childName: ['pie', 'legend'],
                        mutation: () => {
                            return null;
                        }
                    }
                ];
            }
        };
    };

    const { width } = useWindowDimensions();
    const fontSize = getFontSize(width);

    try {
        console.log(selectedCountry, countryTimezone.getTimezonesWithCountryCode(selectedCountry));
    } catch (e) {
        console.log(e);
    }

    return (
        <Box className={style.serviceComponentWrapper}>
            <Box className={style.cardTitle}>
                <Typography variant="h6">{'Stats'}</Typography>
            </Box>

            <Box className={style.emptyCard}>
                {countryData && (
                    <>
                        <Box className={style.countryPicker}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedCountry}
                                label="Country"
                                onChange={handleChange}
                            >
                                {countries.map((id) => (
                                    <MenuItem key={id} value={id}>
                                        <Flag height="24" width="24" code={id} className="flagIcon" />
                                        <Typography variant="small1" sx={{ ml: 1 }}>
                                            {getCountryName(id)}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                        <Typography
                            variant="small4"
                            className={style.subTitle}
                        >{`Total ${totalProduction} MW - Production in ${getFormattedDate({
                            value: timestamp,
                            timezone: countryTimezone.getTimezonesWithCountryCode(selectedCountry)?.[0] || 'UTC',
                            type: DATE_SETTINGS.shortFormatHoursIncluded
                        })}`}</Typography>
                        <VictorySharedEvents
                            key={selectedCountry}
                            events={[
                                {
                                    childName: ['pie', 'legend'],
                                    target: 'data',
                                    eventHandlers: getMouseOverHandler()
                                },
                                {
                                    childName: ['pie', 'legend'],
                                    target: 'labels',
                                    eventHandlers: getMouseOverHandler()
                                }
                            ]}
                        >
                            <VictoryPie
                                width={220}
                                height={120}
                                name="pie"
                                padding={{
                                    right: 0,
                                    left: 0,
                                    top: 15
                                }}
                                style={{ data: { fillOpacity: 0.9, stroke: 'white', strokeWidth: 1 } }}
                                colorScale={colorScale}
                                data={data}
                                labels={() => ''}
                            />
                            <VictoryLegend
                                colorScale={colorScale}
                                x={0}
                                y={0}
                                name={'legend'}
                                gutter={10}
                                rowGutter={-8}
                                centerTitle
                                data={selectedLegend as any}
                                style={{ labels: { fontSize: fontSize, fontFamily: 'Montserrat, sans-serif' } }}
                                labelComponent={<VictoryLabel />}
                            />
                        </VictorySharedEvents>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default Stats;
