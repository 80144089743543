import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Stack, Box, FormHelperText, InputLabel, Typography } from '@mui/material';

import { ROUTES } from '../../../utils/config';
import Logo from '../../../assets/images/logo.svg';
import { loginAsync } from '../../../stores/Auth';
import { LoginBody } from '../../../interfaces/uiv2';
import { useAppDispatch, useAppSelector } from '../../../stores';
import Illustration from '../../../assets/images/login-illustration.png';

import style from './index.module.scss';

const Login = () => {
    const { t } = useTranslation('auth/login');
    const dispatch = useAppDispatch();
    const { loading } = useAppSelector((state) => state.login);

    const schema = yup
        .object({
            email: yup.string().required(t('requiredUsername')),
            password: yup.string().required(t('requiredPassword'))
        })
        .required();

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm<LoginBody>({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: LoginBody) => dispatch(loginAsync(data));

    return (
        <Box className={style.authWrapper}>
            <Box className={style.login}>
                <Box className={style.illustration}>
                    <img src={Illustration} alt={t('login')} />
                </Box>

                <Box className={style.content}>
                    <Box className={style.logoContainer}>
                        <Link to={ROUTES.LOGIN}>
                            <img className={style.logo} src={Logo} />
                        </Link>
                    </Box>

                    <Box className={style.container}>
                        <Typography className={style.title} variant="h4">
                            {t('title')}
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                                name={'email'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel className={style.label} shrink htmlFor={t('email')}>
                                            {t('email')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            fullWidth
                                            type="email"
                                            id={t('email')}
                                            placeholder={t('emailPlaceholder')}
                                        />
                                        <FormHelperText error>{errors.email?.message}</FormHelperText>
                                    </>
                                )}
                                defaultValue={''}
                            />

                            <Controller
                                name={'password'}
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <InputLabel className={style.label} shrink htmlFor={t('password')}>
                                            {t('password')}
                                        </InputLabel>
                                        <Input
                                            {...field}
                                            id={t('password')}
                                            fullWidth
                                            type="password"
                                            placeholder={t('passwordPlaceholder')}
                                        />
                                        <FormHelperText error>{errors.password?.message}</FormHelperText>
                                    </>
                                )}
                                defaultValue={''}
                            />

                            <Stack spacing={2} direction="row">
                                <LoadingButton
                                    type="submit"
                                    variant="primary"
                                    loading={loading}
                                    disabled={loading}
                                    sx={{ flex: 1 }}
                                >
                                    {t('signIn')}
                                </LoadingButton>
                                <Link to={ROUTES.RESET_PASSWORD}>
                                    <Typography className={style.primary} variant="small1">
                                        {t('forgotPassword')}
                                    </Typography>
                                </Link>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Login;
