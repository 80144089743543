import { useContext } from 'react';
import { MODAL_IDS } from '../../modals';
import { TableActions } from '../BaseTable';
import { useAppDispatch } from '../../stores';
import { downloadVPPData } from '../../stores/VPP';
import { downloadReport } from '../../stores/Reports';
import { ModalContext } from '../../hooks/modalContext';
import { downloadAssetData } from '../../stores/Assets';
import { downloadForecast } from '../../stores/Forecasts';
import { DownloadButtonProps, DownloadExtension } from '../DownloadButton';
import { GenerationAssetFE } from '../../interfaces/uiv2';
import { downloadEVSForecast, downloadEVSReport } from '../../stores/EVS';

export const TableActionsEntityContainer = ({
    id,
    className,
    entityType,
    remove = true,
    asset,
    extraActions = [],
    params,
    defaultActions = []
}: {
    asset?: GenerationAssetFE;
    id: string;
    remove?: boolean;
    entityType: 'report' | 'forecast' | 'asset' | 'vpp' | 'gas-consumer' | 'gas-cluster' | 'evs' | 'evs-reports';
    extraActions?: any[];
    className?: string;
    params?: any;
    defaultActions?: DownloadExtension[];
}) => {
    const { handleClickOpen } = useContext(ModalContext);

    const dispatch = useAppDispatch();

    const download = (type: DownloadButtonProps['type']) => {
        if (entityType === 'forecast') {
            dispatch(downloadForecast({ ids: [id], type }));
            return;
        }

        if (entityType === 'report') {
            dispatch(downloadReport({ ids: [id], type }));
        }

        if (entityType === 'asset' && asset) {
            dispatch(downloadAssetData({ asset, file_type: type, from_datetime: params.from, to_datetime: params.to }));
        }

        if (entityType === 'vpp') {
            dispatch(downloadVPPData({ ids: [id], type }));
        }

        if (entityType === 'evs') {
            dispatch(downloadEVSForecast({ ...params, file_type: type }));
        }

        if (entityType === 'evs-reports') {
            console.log('ANDREEA', params);
            dispatch(downloadEVSReport({ params, ids: [id], type }));
        }
    };

    if (remove) {
        extraActions.push({
            id: 'delete',
            label: 'Delete',
            callback: () => {
                handleClickOpen(MODAL_IDS.CONFIRMATION_MODAL);
            }
        });
    }

    return (
        <TableActions
            className={className}
            actions={[
                ...extraActions,
                defaultActions.includes(DownloadExtension.Pdf)
                    ? {
                          id: 'downloadPdf',
                          label: 'Download PDF',
                          callback: () => {
                              download(DownloadExtension.Pdf);
                          }
                      }
                    : null,
                defaultActions.includes(DownloadExtension.Excel)
                    ? {
                          id: 'downloadExcel',
                          label: 'Download Excel',
                          callback: () => {
                              download(DownloadExtension.Excel);
                          }
                      }
                    : null,
                defaultActions.includes(DownloadExtension.Csv)
                    ? {
                          id: 'downloadCsv',
                          label: 'Download CSV',
                          callback: () => {
                              download(DownloadExtension.Csv);
                          }
                      }
                    : null
            ].filter((x) => x)}
        />
    );
};
