import dayjs from 'dayjs';

import { DEFAULT_CAPACITY_UNIT } from './table';
import { DATE_SETTINGS, NO_VALUE, ROUTES } from './config';
import { UNIT } from '../interfaces/uiv2';
import SettingsIcon from '@mui/icons-material/Settings';

import { ReactComponent as ConsumersIcon } from '../assets/icons/demand/consumers.svg';
import { ReactComponent as ClustersIcon } from '../assets/icons/demand/clusters.svg';
import { ReactComponent as SegmentsIcon } from '../assets/icons/demand/segments.svg';
import { ReactComponent as ProfilesIcon } from '../assets/icons/demand/profiles.svg';

/* istanbul ignore next */
export function debounce(func: any, timeout = 300) {
    let timer: ReturnType<typeof setTimeout>;
    return (...args: any) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            // todo: fix this
            // eslint-disable-next-line
            // @ts-ignore
            func.apply(this, args);
        }, timeout);
    };
}

export const mockRequest = <T>(timeout: number, payload: T, params?: any) => {
    console.info(params);
    return new Promise(function (myResolve) {
        setTimeout(function () {
            myResolve(payload);
        }, timeout);
    });
};

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomArbitrary(min: number, max: number) {
    return Math.random() * (max - min) + min;
}

interface FormattedDateProps {
    value: string | number | Date | null | undefined;
    fallback?: any;
    type?: string;
    timezone?: string | null;
}

export function getFormattedDate({
    value,
    fallback = null,
    type = DATE_SETTINGS.shortFormat,
    timezone
}: FormattedDateProps) {
    if (!value) {
        return fallback;
    }
    if (dayjs(value).isValid()) {
        const date = dayjs.tz(value, 'UTC');
        if (timezone) {
            return dayjs.tz(date, timezone).format(type);
        }
        return date.format(type);
    }
    return fallback;
}

export function getFormattedCapacityValue(value: number, unit = DEFAULT_CAPACITY_UNIT, showUnit = true) {
    if (value === 0) {
        return `0 ${showUnit ? unit : ''}`;
    }
    if (!value) {
        return NO_VALUE;
    }
    return `${(value / 1000).toFixed(1)} ${showUnit ? unit : ''}`;
}

export const isNumeric = (str: any) => {
    if (str === null) {
        return false;
    }
    if (typeof str === 'number') return true; // because sometimes its a number, vpp forecast
    if (typeof str != 'string') return false; // we only process strings!
    return (
        !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
};

export const transformToMW = (x: number, decimals = 3) => {
    return toFixed(x / 1000, decimals);
};

export const toFixed = (x: number, decimals = 3): number => {
    return +x.toFixed(decimals);
};

export const convertMeasureUnitToUnitId = (measureUnit: UNIT) => {
    switch (measureUnit) {
        case UNIT.ENERGY:
            return 'MWh';
        case UNIT.LOW_ENERGY:
            return 'kWh';
        case UNIT.POWER:
            return 'MW';
        case UNIT.USD:
            return 'USD';
        case UNIT.M3:
            return 'm³';
        default:
            return measureUnit;
    }
};

export const secondsInADay = 86400;
export const msInAMinute = 60000;

export const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const getDemandIcon = (type: 'consumer' | 'profile' | 'cluster' | 'segment' | 'default') => {
    switch (type) {
        case 'consumer':
            return ConsumersIcon;
        case 'profile':
            return ProfilesIcon;
        case 'cluster':
            return ClustersIcon;
        case 'segment':
            return SegmentsIcon;
        case 'default':
            return SettingsIcon;
        default:
            return SettingsIcon;
    }
};

// DashboardItem
export const getDashboardItemType = (item: any) => {
    return item.consumer_id
        ? 'consumer'
        : item.profile_id
        ? 'profile'
        : item.cluster
        ? 'cluster'
        : item.segment
        ? 'segment'
        : 'default';
};

export const getDemandRoute = (type: 'consumer' | 'profile' | 'cluster' | 'segment' | 'default', id = '') => {
    switch (type) {
        case 'consumer':
            return `${ROUTES.AI_DEMAND_CONSUMERS_PAGE.replace(':id', id)}`;
        case 'profile':
            return `${ROUTES.AI_DEMAND_PROFILES_PAGE.replace(':id', id)}`;
        case 'cluster':
            return `${ROUTES.AI_DEMAND_CLUSTERS_PAGE.replace(':id', id)}`;
        case 'segment':
            return `${ROUTES.AI_DEMAND_SEGMENTS_PAGE.replace(':id', id)}`;
        case 'default':
            return ROUTES.AI_DEMAND;
        default:
            return ROUTES.AI_DEMAND;
    }
};

export const extractDate = (inputDate: string) => {
    // Split the input date string by the '.' separator
    const dateComponents = inputDate.split('.');

    // Check if the input date has at least three components (day, month, year)
    if (dateComponents.length >= 3) {
        // Join the first two components (day and month) with a '.' separator
        return dateComponents.slice(0, 2).join('.');
    } else {
        return '';
    }
};

export const toReversed = (arr: any[]) => {
    return Array.from(arr).reverse();
};
