import dayjs from 'dayjs';
import { FC, useEffect, useRef, useState } from 'react';
import { ColorType, createChart, IChartApi, ISeriesApi, LineData, LineStyle, Time } from 'lightweight-charts';

import { UNIT } from '../../interfaces/uiv2';
import { downloadChart } from '../../stores/Assets';
import { useAppDispatch, useAppSelector } from '../../stores';
import { convertMeasureUnitToUnitId, debounce, secondsInADay, toFixed } from '../../utils/helpers';

interface TradingViewChartProps {
    data: LineData[][];
    colors: string[];
    fetchMore: (from: string) => void;
    measureUnit: UNIT;
    limits?: boolean[];
}

export const TradingViewChart: FC<TradingViewChartProps> = (props) => {
    const { data, colors, measureUnit, limits = [] } = props;

    const { backgroundColor, textColor } = {
        backgroundColor: '#fbfcff',
        textColor: 'rgba(0, 0, 0, 0.6)'
    };

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<IChartApi | null>(null);
    const dispatch = useAppDispatch();
    const { download } = useAppSelector((state) => state.assetPage);
    // const [series, setSeries] = useState<ISeriesApi<'Line'>[]>([]);

    const [fetchedMore, setFetchedMore] = useState(false);
    const [timeRange, setTimeRange] = useState<any>();

    useEffect(() => {
        if (chartContainerRef.current) {
            const handleResize = () => {
                chart.applyOptions({ width: chartContainerRef?.current?.clientWidth });
            };

            const chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { type: ColorType.Solid, color: backgroundColor },
                    textColor,
                    fontFamily: 'Montserrat'
                },
                width: chartContainerRef?.current?.clientWidth,
                height: chartContainerRef?.current?.parentElement?.clientHeight || 380,
                timeScale: {
                    timeVisible: true,
                    secondsVisible: true,
                    shiftVisibleRangeOnNewBar: false,
                    borderColor: '#D5E3FF'
                },
                grid: {
                    vertLines: {
                        color: '#D5E3FF'
                    },
                    horzLines: {
                        color: '#D5E3FF'
                    }
                },
                rightPriceScale: {
                    borderColor: '#D5E3FF'
                },
                crosshair: {
                    vertLine: {
                        color: '#979797',
                        width: 1,
                        style: LineStyle.Dashed
                    },
                    horzLine: {
                        color: '#979797',
                        width: 1,
                        style: LineStyle.Dashed
                    }
                },
                localization: {
                    priceFormatter: (priceValue: any) => {
                        return `${toFixed(priceValue)} ${convertMeasureUnitToUnitId(measureUnit)}`;
                    }
                }
            });

            chartRef.current = chart;

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chartRef?.current?.remove();
            };
        }

        return () => {
            chartContainerRef.current?.remove();
        };
    }, []);

    useEffect(() => {
        const timeScale = chartRef?.current?.timeScale();
        if (chartRef.current) {
            const series = data.map((item, index) => {
                const s = chartRef?.current?.addLineSeries({
                    color: colors[index],
                    baseLineColor: colors[index]
                }) as ISeriesApi<'Line'>;

                // throws error that e is not extensible and cannot add the property _internal_originalTime so we add it before.
                s?.setData(item.map((e) => ({ ...e, _internal_originalTime: e.time })));
                //s?.setData(item);

                return s;
            });

            const timeHandler = debounce(() => {
                const logicalRange = timeScale?.getVisibleLogicalRange();
                let from = null;

                if (logicalRange) {
                    for (const [index, s] of series.entries()) {
                        const barsInfo = s?.barsInLogicalRange(logicalRange);

                        console.log(barsInfo?.barsBefore);

                        if (barsInfo && barsInfo.barsBefore < 20 && !limits[index]) {
                            const f = (data[index][0].time as number) * 1000;

                            const newFrom = new Date(f).toDateString();
                            if (!dayjs(from).isValid() || !dayjs(from).isSame(dayjs(newFrom))) {
                                from = newFrom;
                            }
                        }
                    }
                }

                if (from) {
                    const visibleRange = timeScale?.getVisibleRange();
                    if (visibleRange) {
                        setTimeRange(visibleRange);
                    }
                    props.fetchMore(from);
                    setFetchedMore(true);
                    from = null;
                }
            }, 250);

            let shouldFit = false;

            for (const serie of series) {
                const logicalRange = timeScale?.getVisibleLogicalRange();

                if (logicalRange) {
                    const barsInfo = serie?.barsInLogicalRange(logicalRange);

                    // if there are no bars before the logical range, it means the chart has a few data points and we need to fit the content.
                    if (barsInfo && barsInfo.barsBefore < 0) {
                        shouldFit = true;
                    }
                }
            }

            if (!fetchedMore) {
                if (shouldFit) {
                    timeScale?.fitContent();
                } else {
                    const condition = data.some((dataset) =>
                        dataset.map((x) => x.time).includes(dayjs().unix() as Time)
                    );
                    if (condition) {
                        timeScale?.setVisibleRange({
                            from: (new Date().getTime() / 1000) as Time,
                            to: (new Date().getTime() / 1000 + secondsInADay) as Time
                        });
                    }
                }
            } else {
                // something to stop changing the timescale visible range
                if (timeRange && !shouldFit) {
                    timeScale?.setVisibleRange(timeRange);
                }
            }

            timeScale?.subscribeVisibleLogicalRangeChange(timeHandler);

            return () => {
                for (const s of series) {
                    if (s) {
                        chartRef?.current?.removeSeries(s);
                    }
                }

                timeScale?.unsubscribeVisibleLogicalRangeChange(timeHandler);
            };
        }
    }, [data, colors, chartRef, timeRange]);

    useEffect(() => {
        if (download) {
            takeScreenshot();
        }
    }, [download]);

    const takeScreenshot = () => {
        const content = chartRef.current?.takeScreenshot();
        function blobCallback(iconName: string) {
            return (b: Blob | null) => {
                if (b) {
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style.display = 'none';
                    a.download = `${iconName}.png`;
                    a.href = window.URL.createObjectURL(b);
                    a.click();
                    a.remove();
                    dispatch(downloadChart(false));
                }
            };
        }
        content?.toBlob(blobCallback('chart'), 'image/png');
    };

    return <div ref={chartContainerRef} />;
};
