import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { Link, useParams } from 'react-router-dom';

import {
    loadMore,
    getReportAsync,
    getReportDataAsync,
    resetListReportsState,
    deleteReportsListAsync,
    setGetParamsReportPage
} from '../../stores/Reports';
import Loading from '../../layout/Loading';
import { UNIT } from '../../interfaces/uiv2';
import { TAB } from '../../interfaces/general';
import { getFormattedDate } from '../../utils/helpers';
import { getAssetIcon } from '../../utils/getAssetIcon';
import { PageContainer } from '../../layout/PageContainer';
import { TradingView } from '../../components/TradingView';
import { getTimezoneByCountry } from '../../utils/forecast';
import { useAppDispatch, useAppSelector } from '../../stores';
import { getTableHeadCells, renderFooterContent } from './helpers';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { DownloadExtension } from '../../components/DownloadButton';
import { DATE_SETTINGS, NO_VALUE, ROUTES } from '../../utils/config';
import { ReactComponent as GraphIcon } from '../../assets/icons/timeline.svg';
import { ReactComponent as ViewList } from '../../assets/icons/view_list.svg';
import { RemoveForecastContainer } from '../../components/RemoveEntityContainer';
import { TableActionsEntityContainer } from '../../components/TableActionsEntityContainer';
import MemoizedGenericTable from '../../components/GenericTableWrapper/GenericTableWrapper';
import { CircleProgressBar } from '../../components/BaseTable/CustomCells/CircleProgressBar';
import { EntityPageToolbar, EntityPageToolbarButton } from '../../components/EntityPageToolbar';
import { ReactComponent as ReportsSVG } from '../../assets/icons/navigation-items/dots-menu-reports.svg';

import common from '../../common.module.scss';
import style from '../../features/asset-page/asset-page.module.scss';

const ReportPage = () => {
    const query = useParams();
    const dispatch = useAppDispatch();

    const { t } = useTranslation();
    const { report, pager, getParams, loading, loadingData, chartData, errorGettingData, paginatedData, success } =
        useAppSelector((state) => state.reportPage);

    const [tab, setTab] = useState<TAB>(TAB.CHART);
    const pageTitle = `reportPageTitle.${tab}`;
    const headCells = getTableHeadCells({
        t,
        country: report ? report.asset.country_code! : '',
        assetMeasureUnit: report ? report.asset.measure_unit : UNIT.ENERGY
    });

    useEffect(() => {
        return () => {
            dispatch(resetListReportsState());
        };
    }, []);

    useEffect(() => {
        if (query?.publicId) {
            dispatch(getReportAsync(query.publicId));
        }
    }, [query?.publicId]);

    useEffect(() => {
        if (report) {
            dispatch(getReportDataAsync(report.id));
        }
    }, [report?.id]);

    const handleTabChange = (value: TAB) => {
        setTab(value);
    };

    useEffect(() => {
        if (tab === TAB.TABLE && pager?.page !== getParams.page) {
            dispatch(loadMore());
        }
    }, [getParams, pager, tab]);

    if (!report && loading) {
        return (
            <Box className={common.spinnerContainer}>
                <Loading className={common.dataLoading} />
            </Box>
        );
    }

    if (!report) {
        return null;
    }

    const getAssetPageUrl = () => {
        return `${ROUTES.AI_GENERATION_ASSETS_PAGE.replace(':id', report!.asset.id)}`;
    };

    const remove = () => {
        dispatch(deleteReportsListAsync([report.id]));
    };

    return (
        <PageContainer className={[style.pageContainer]}>
            <Box className={style.flex}>
                <Box className={style.headerContainer}>
                    <Box className={style.header}>
                        <PageHeader
                            className={classNames(style.align, style.primary)}
                            title={t('title.reports')}
                            id={report.id}
                            icon={<ReportsSVG />}
                            subtitle={report.name}
                        />
                        <Box className={style.subtitle} sx={{ ml: 2 }}>
                            <Box className={style.dot} />
                            <Typography variant="small1"> {t(pageTitle)}</Typography>
                        </Box>
                    </Box>

                    <Box className={style.toolbarContainer}>
                        <EntityPageToolbar>
                            <EntityPageToolbarButton
                                tabId={TAB.CHART}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={t('assetPageTitle.chart')}
                            >
                                <GraphIcon className={style.chartIcon} />
                            </EntityPageToolbarButton>
                            <EntityPageToolbarButton
                                tabId={TAB.TABLE}
                                activeTabId={tab}
                                handleTabChange={handleTabChange}
                                label={t('assetPageTitle.table')}
                            >
                                <ViewList />
                            </EntityPageToolbarButton>
                        </EntityPageToolbar>
                        <TableActionsEntityContainer
                            entityType="report"
                            id={report.id}
                            className={style.actionsWrapper}
                            defaultActions={[DownloadExtension.Csv, DownloadExtension.Excel, DownloadExtension.Pdf]}
                        />
                    </Box>
                </Box>

                <Box className={classNames(style.subHeaderContainer, tab === 'table' ? common.borderBottom : '')}>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4">{t('assetInfo.type')}</Typography>
                        <Box component="span" sx={{ ml: 1.5 }} className={style.align}>
                            {getAssetIcon(report.asset.type, 'm-r-1')}
                            <Typography variant="small3">{t(`assetTypes.${report.asset.type}`)}</Typography>
                        </Box>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border)}>
                        <Typography variant="small4" sx={{ mr: 1.5 }}>
                            {t('assetInfo.asset')}
                        </Typography>
                        <Link to={getAssetPageUrl()}>
                            <Typography variant="small3" className={style.primary}>
                                {report.asset.name}
                            </Typography>
                        </Link>
                    </Box>
                    <Box className={classNames(style.subHeaderElement, style.border, style.margin)}>
                        <Typography variant="small4">{t('assetInfo.from')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5, mr: 1.5 }}>
                            {getFormattedDate({
                                value: report.from_date,
                                type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                                timezone: report.asset.timezone || getTimezoneByCountry(report.asset.country_code)
                            })}
                        </Typography>
                        <Typography variant="small4">{t('assetInfo.to')}</Typography>
                        <Typography variant="small3" sx={{ ml: 1.5 }}>
                            {getFormattedDate({
                                value: report.to_date,
                                type: DATE_SETTINGS.shortV2FormatHoursIncluded,
                                timezone: report.asset.timezone || getTimezoneByCountry(report.asset.country_code)
                            })}
                        </Typography>
                    </Box>
                    <Box className={classNames(style.subHeaderElement)}>
                        <Typography variant="small4">{t('assetInfo.accuracy')}</Typography>
                        <Box component="span" className={common.center} sx={{ ml: 1.5 }}>
                            {report.accuracy ? <CircleProgressBar value={report.accuracy * 100} /> : NO_VALUE}
                        </Box>
                    </Box>
                </Box>

                {tab === 'chart' && (
                    <TradingView
                        data={chartData}
                        loading={loadingData}
                        error={errorGettingData}
                        fetchMoreFn={() => {}}
                        assetMeasureUnit={report.asset.measure_unit}
                    />
                )}

                {tab === 'table' && (
                    <MemoizedGenericTable
                        data={paginatedData}
                        loading={loadingData}
                        error={errorGettingData}
                        success={success}
                        pager={pager}
                        headCells={headCells}
                        renderFooterContent={renderFooterContent}
                        setGetParams={setGetParamsReportPage}
                    />
                )}
            </Box>
            <RemoveForecastContainer cb={remove} stateAccessor="deleteReports" />
        </PageContainer>
    );
};

export default ReportPage;
