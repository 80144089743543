import i18next from 'i18next';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from './axios';
import ToastifyType from './toastify-config';
import { Forecast } from '../interfaces/apiv2';
import { getQueryParamsFromObject } from './table';
import { commonReduxErrorHandler } from './error-handling';
import { DownloadExtension } from '../components/DownloadButton';
import { ForecastDataPayload } from '../interfaces/uiv2';
import dayjs from 'dayjs';
import { DATE_SETTINGS } from './config';

export const getDownloadThunk = ({
    thunkName,
    endpoint,
    bodyPropName,
    filename
}: {
    thunkName: string;
    endpoint: string;
    bodyPropName?: string;
    filename: string;
}) => {
    return createAsyncThunk(
        thunkName,
        async (
            { ids, type, name, params }: { ids: string[]; type: DownloadExtension; name?: string; params?: any },
            { rejectWithValue }
        ) => {
            toast.success(i18next.t('downloadStarted'), ToastifyType.success);
            try {
                console.log('robert', endpoint, ids, type, name, params);
                const endp = endpoint.includes(':projectId')
                    ? endpoint.replace(':projectId', params.projectId)
                    : endpoint;
                const url = bodyPropName
                    ? `${endp}${getQueryParamsFromObject({ [bodyPropName]: ids, extension: type })}`
                    : endp;
                const response = await axios.get(url, {
                    responseType: 'blob'
                });

                const extension = type === 'Excel' ? 'xlsx' : type.toLowerCase();
                if (ids.length > 1 && thunkName === 'report_list/download') {
                    name = `Ogre_Reports_${dayjs().format(DATE_SETTINGS.longFormat)}`;
                }
                if (ids.length > 1 && thunkName === 'forecast_list/download') {
                    name = `Ogre_Forecasts_${dayjs().format(DATE_SETTINGS.longFormat)}`;
                }

                fileDownload(response.data, `${name || filename}.${ids.length === 1 ? extension : 'zip'}`);
                toast.success(i18next.t('downloadSuccess'), ToastifyType.success);
            } catch (e) {
                toast.error(i18next.t('commonErrorMessage'), ToastifyType.error);
                return rejectWithValue(commonReduxErrorHandler(e));
            }
        }
    );
};

export const getDemandChartThunk = ({
    thunkName,
    endpoint,
    module,
    submodule
}: {
    thunkName: string;
    module: 'electricity' | 'gas';
    endpoint: 'historical' | 'forecast' | 'reporting';
    submodule: 'clusters' | 'profiles' | 'segments' | 'consumers';
}) => {
    return createAsyncThunk(thunkName, async (values: ForecastDataPayload, { rejectWithValue }) => {
        try {
            const url = `/${module}/demand/${values.projectId}/${submodule}/${values.id}/${endpoint}`;
            const response = await axios.get<Forecast[]>(url, {
                // cache: {
                //     ttl: 1000 * 60 * 15
                // },
                params: {
                    from_datetime: values.from,
                    to_datetime: values.to,
                    forecast_type: values.forecast_type,
                    time_increment: values.time_increment
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    });
};
export const getAssetPageChartThunk = ({
    thunkName,
    endpoint
}: {
    thunkName: string;
    endpoint: 'historical' | 'forecast';
}) => {
    return createAsyncThunk(thunkName, async (values: ForecastDataPayload, { rejectWithValue }) => {
        try {
            const response = await axios.get<Forecast[]>(`/electricity/generation/assets/${values.id}/${endpoint}`, {
                // cache: {
                //     ttl: 1000 * 60 * 15
                // },
                params: {
                    from_datetime: values.from,
                    to_datetime: values.to,
                    forecast_type: values.forecast_type,
                    time_increment: values.time_increment,
                    zerofill: true
                }
            });

            return response.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    });
};

export const getVppPageChartThunk = ({
    thunkName,
    endpoint
}: {
    thunkName: string;
    endpoint: 'historical' | 'forecast';
}) => {
    return createAsyncThunk(thunkName, async (values: ForecastDataPayload, { rejectWithValue }) => {
        try {
            const response = await axios.get<{ data: Forecast[] }>(
                `/electricity/generation/assets/vpp/${values.id}/${endpoint}`,
                {
                    // cache: {
                    //     ttl: 1000 * 60 * 15
                    // },
                    params: {
                        from_datetime: values.from,
                        to_datetime: values.to,
                        forecast_type: values.forecast_type,
                        time_increment: values.time_increment,
                        zerofill: true
                    }
                }
            );

            return response.data.data;
        } catch (e) {
            return rejectWithValue(commonReduxErrorHandler(e));
        }
    });
};
