import { TFunction } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ReportDataFE, UNIT } from '../../interfaces/uiv2';
import { getTimezoneByCountry } from '../../utils/forecast';
import { TableCellRenderer } from '../../interfaces/general';
import { DefaultSearchableCell } from '../../components/BaseTable';
import { DATE_SETTINGS, NO_VALUE, NO_VALUE_ZERO } from '../../utils/config';
import { toFixed, transformToMW, getFormattedDate, convertMeasureUnitToUnitId } from '../../utils/helpers';

export const renderFooterCellWrapper = (value: any, title: string) => {
    return (
        <Box component="span" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant={'small4'} sx={{ mb: 0.5 }}>
                {title}
            </Typography>
            <Typography variant={'small3'}>{value}</Typography>
        </Box>
    );
};

export const renderFooterContent = (data: ReportDataFE[], t: TFunction) => {
    return {
        _id: 'customReportsFooter',
        customFooter: true,
        index: t('customFooter.total'),
        date: t('customFooter.allIntervals'),
        time: t('customFooter.allIntervals'),
        measured: toFixed(data.reduce((prev, crt) => prev + (+crt.measured || 0), 0)),
        forecasted: toFixed(data.reduce((prev, crt) => prev + (+crt.forecasted || 0), 0)),
        excess: toFixed(data.reduce((prev, crt) => prev + (+crt.excess || 0), 0)),
        deficit: toFixed(data.reduce((prev, crt) => prev + (+crt.deficit || 0), 0)),
        error: toFixed(data.reduce((prev, crt) => prev + (+crt.error || 0) / data.length, 0))
    };
};

export const getTableHeadCells = ({
    t,
    country,
    assetMeasureUnit
}: {
    t: TFunction;
    country: string;
    assetMeasureUnit: UNIT;
}): TableCellRenderer<ReportDataFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);

    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return <Typography variant="small4">{row.index < 10 ? `0${row.index}` : `${row.index}`}</Typography>;
            },
            sort: false
        },
        {
            id: 'date',
            label: t('tableHead.date'),
            justifyContent: 'center',
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                const timezone = getTimezoneByCountry(country) || null;
                return (
                    <DefaultSearchableCell
                        value={getFormattedDate({ value: row.timestamp, fallback: NO_VALUE, timezone })}
                        searchValue={searchValue}
                    />
                );
            }
        },
        {
            id: 'time',
            sort_id: 'time',
            justifyContent: 'center',
            label: t('tableHead.time'),
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                const timezone = getTimezoneByCountry(country) || null;
                return (
                    <DefaultSearchableCell
                        value={getFormattedDate({
                            value: row.timestamp,
                            fallback: NO_VALUE,
                            type: DATE_SETTINGS.timeShortFormatFNS,
                            timezone
                        })}
                        searchValue={searchValue}
                    />
                );
            }
        },
        {
            id: 'measured',
            label: `${t('tableHead.measured')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const measured = row.measured ? transformToMW(row.measured, 2) : NO_VALUE_ZERO;
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.measured, `${t('tableFooter.measured')} (${unitId})`)
                            : measured}
                    </Typography>
                );
            }
        },
        {
            id: 'forecasted',
            label: `${t('tableHead.forecasted')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const forecasted = row.forecasted ? transformToMW(row.forecasted, 2) : NO_VALUE_ZERO;
                return (
                    <Typography variant={'small4'}>
                        {row.customFooter
                            ? renderFooterCellWrapper(row.forecasted, `${t('tableFooter.forecasted')} (${unitId})`)
                            : forecasted}
                    </Typography>
                );
            }
        },
        {
            id: 'excess',
            label: `${t('tableHead.excess')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const excess = row.excess ? transformToMW(row.excess, 2) : NO_VALUE_ZERO;
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.excess, `${t('tableFooter.excess')} (${unitId})`)
                            : excess}
                    </Typography>
                );
            }
        },
        {
            id: 'deficit',
            label: `${t('tableHead.deficit')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const deficit = row.deficit ? transformToMW(row.deficit, 2) : NO_VALUE_ZERO;
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.deficit, `${t('tableFooter.deficit')} (${unitId})`)
                            : deficit}
                    </Typography>
                );
            }
        },
        {
            id: 'error',
            label: t('tableHead.error'),
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const err = row.error ? row.error : NO_VALUE_ZERO;
                return (
                    <Typography variant="small4">
                        {row.customFooter ? renderFooterCellWrapper(row.error, t('tableFooter.error')) : err}
                    </Typography>
                );
            }
        }
    ];
};
