import dayjs from 'dayjs';
import { LineData } from 'lightweight-charts';
import { Forecast } from '../interfaces/apiv2';
import { getFormattedDate, isNumeric, toFixed, transformToMW } from './helpers';
import { DATE_SETTINGS, NO_VALUE } from './config';
import ct from 'countries-and-timezones';
import { FORECAST_TYPE, ForecastFE } from '../interfaces/uiv2';

export const removeDuplicatedValues = (data: Forecast[]) => {
    if (!data) return [];
    return data.filter((value, index, self) => index === self.findIndex((t) => t.timestamp === value.timestamp));
};

export const sortForecastData = (data: LineData[]): LineData[] => {
    return data
        .filter((value, index, self) => index === self.findIndex((t) => t.time === value.time))
        .sort((a, b) => (a.time as number) - (b.time as number));
};

export const transformForecastToView = (data: Forecast[], type: FORECAST_TYPE, country?: string): LineData[] => {
    return sortForecastData(
        removeDuplicatedValues(data).map((c) => {
            const timezone = country ? getTimezoneByCountry(country) : 'UTC';
            const date = dayjs.tz(Number(c.timestamp), 'UTC');
            const offset = date.tz(timezone).utcOffset();
            const time = date.add(offset, 'minutes').unix();
            let value = toFixed(Number(Number(c['value']) || 0), type === FORECAST_TYPE.ELECTRICITY ? 3 : 0);

            if (type === FORECAST_TYPE.ELECTRICITY) {
                // we divide the number to 1000 because we receive kW and we are displaying MW.
                value = toFixed(Number((Number(c['value']) || 0) / 1000));
            }

            return {
                time,
                value
            } as LineData;
        })
    );
};

export const transformEvsForecastToView = (data: Forecast[]): LineData[] => {
    return sortForecastData(
        removeDuplicatedValues(data).map((c) => {
            const time = dayjs(c.timestamp).unix();
            const value = toFixed(Number((Number(c['value']) || 0) / 1000));

            console.log({
                time,
                value
            });

            return {
                time,
                value
            } as LineData;
        })
    );
};

export const getTimezoneByCountry = (country?: string) => {
    if (!country) return undefined;
    const countryDetails = ct.getCountry(country.toUpperCase());
    return countryDetails ? countryDetails.timezones[0] : undefined;
};

export const transformForecastToTable = (forecast: Forecast[], country?: string): ForecastFE[] => {
    return forecast.map((e, i) => {
        const date: any = new Date(+e.timestamp);
        const timezone = getTimezoneByCountry(country) || null;

        return {
            ...e,
            value: +e.value,
            index: i + 1,
            date: getFormattedDate({
                value: date,
                fallback: NO_VALUE,
                type: DATE_SETTINGS.shortFormat,
                timezone: 'utc'
            }),
            localDate:
                timezone &&
                getFormattedDate({
                    value: date,
                    fallback: undefined,
                    type: DATE_SETTINGS.shortFormat,
                    timezone
                }),
            time: getFormattedDate({
                value: date,
                fallback: NO_VALUE,
                type: DATE_SETTINGS.timeShorterFormat,
                timezone: 'utc'
            }),
            localTime:
                timezone &&
                getFormattedDate({
                    value: date,
                    fallback: undefined,
                    type: DATE_SETTINGS.timeShorterFormat,
                    timezone
                }),
            timestamp: +e.timestamp,
            P10: e.P10 ? (isNumeric(e.P10) ? transformToMW(+e.P10) : 0) : transformToMW(+e.value),
            P25: e.P25 ? (isNumeric(e.P25) ? transformToMW(+e.P25) : 0) : transformToMW(+e.value),
            P50: e.P50 ? (isNumeric(e.P50) ? transformToMW(+e.P50) : 0) : transformToMW(+e.value),
            P75: e.P75 ? (isNumeric(e.P75) ? transformToMW(+e.P75) : 0) : transformToMW(+e.value),
            P90: e.P90 ? (isNumeric(e.P90) ? transformToMW(+e.P90) : 0) : transformToMW(+e.value)
        };
    });
};

export const transformEvsForecastToTable = (forecast: Forecast[], country?: string): any[] => {
    return forecast.map((e, i) => {
        const date: any = dayjs(e.timestamp);
        const timezone = getTimezoneByCountry(country) || null;

        return {
            ...e,
            value: +e.value,
            index: i + 1,
            date: getFormattedDate({
                value: date,
                fallback: NO_VALUE,
                type: DATE_SETTINGS.shortFormat,
                timezone: 'utc'
            }),
            localDate:
                timezone &&
                getFormattedDate({
                    value: date,
                    fallback: undefined,
                    type: DATE_SETTINGS.shortFormat,
                    timezone
                }),
            time: getFormattedDate({
                value: date,
                fallback: NO_VALUE,
                type: DATE_SETTINGS.timeShorterFormat,
                timezone: 'utc'
            }),
            localTime:
                timezone &&
                getFormattedDate({
                    value: date,
                    fallback: undefined,
                    type: DATE_SETTINGS.timeShorterFormat,
                    timezone
                }),
            timestamp: e.timestamp
        };
    });
};
